export enum KundeRoleEnums {
  SYSTEM_ADMIN = "SystemAdmin",
  KOMMUNE_ADMIN = "KommuneAdmin",
  OKONOMI_ADMIN = "OkonomiAdmin",
  SAGSBEHANDLER_ADMIN = "SagsbehandlerAdmin",
  SAGSBEHANDLER_VIRKSOMHED = "SagsbehandlerVirksomhed",
  SAGSBEHANDLER_LANDBRUG = "SagsbehandlerLandbrug",
  SAGSBEHANDLER_VANDANLAEG = "SagsbehandlerVandanlaeg",
  SAGSBEHANDLER_AARSPLAN = "SagsbehandlerAarsplan",
  SAGSBEHANDLER_VIRKSOMHED_LAES = "SagsbehandlerVirksomLaes",
  SAGSBEHANDLER_LANDBRUG_LAES = "SagsbehandlerLandbrugLaes",
  SAGSBEHANDLER_VANDANLAEG_LAES = "SagsbehandlerVandanlaegLaes",
  SAGSBEHANDLER_AFFALD = "SagsbehandlerAffald",
  TIMEOUT = "TimeOut"
}

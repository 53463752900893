import { defineStore } from "pinia";
import moment from "moment/moment";
import { useSessionStore } from "@/stores/session";
import { KundeRoleEnums } from "@/models/KundeRoleEnums";
import { useRoute } from "vue-router";

export const useMainStore = defineStore("main", {
  state: () => {
    return {
      userSettingsModalVisible: false,
      httpErrorModalVisible: false,
      forbiddenErrorModalVisible: false,
      httpError: {
        errorModalContent: "",
        errorModalStacktrace: "",
        errorModalAdvancedContent: "",
        errorModalShowAdvancedContent: false,
        timeStamp: null
      },
      forbiddenError: {
        forbiddenModalContent: ""
      }
    };
  },
  actions: {
    openHttpErrorModal(error: any) {
      const route = useRoute();

      if (route) {
        if (route.name === "login" || route.name === "swecologin") {
          return;
        }
      }

      //Reset
      this.httpError.errorModalContent = "";
      this.httpError.errorModalStacktrace = "";
      this.httpError.errorModalAdvancedContent = "";
      this.httpError.errorModalShowAdvancedContent = false;
      this.httpError.timeStamp = moment().format("DD-MM-YYYY HH:mm:ss");

      const isProduction = import.meta.env.PROD;

      if (error === undefined) {
        this.httpError.errorModalContent = "Kunne ikke oprette forbindelse til serveren";
      } else if (error.status === 401) {
        this.httpError.errorModalContent = "Der er ikke de fornødne rettigheder til denne handling.";
      } else if (error.status === 500) {
        if (error.data && error.data.exception && error.data.exception["FejlBesked"]) {
          this.httpError.errorModalContent = error.data.exception["FejlBesked"];
        } else {
          this.httpError.errorModalContent = "Der opstod en uventet fejl, prøv igen. Kontakt Miljøweb support hvis problemet fortsætter.";
        }
      } else {
        this.httpError.errorModalContent = "Der opstod en uventet fejl";
      }

      const sessionStore = useSessionStore();
      const isAdministrator = sessionStore.hasRights(KundeRoleEnums.SYSTEM_ADMIN);

      if (!isProduction || isAdministrator) {
        const exception = error?.data?.exception;

        if (exception) {
          if (exception["StackTraceString"]) {
            this.httpError.errorModalStacktrace = error.data.exception["StackTraceString"];
          } else if (exception["StackTrace"]) {
            this.httpError.errorModalStacktrace = error.data.exception["StackTrace"];
          }

          if (exception["Message"]) {
            this.httpError.errorModalAdvancedContent = error.data.exception["Message"];
          } else if (error?.data?.detail) {
            this.httpError.errorModalAdvancedContent = error.data.detail;
          }
        }

        if (error?.data?.status === 400 && error?.data?.title) {
          this.httpError.errorModalAdvancedContent = error.data.title + "\n\n" + JSON.stringify(error.data.errors);
        }

        this.httpError.errorModalShowAdvancedContent =
          this.httpError.errorModalAdvancedContent !== "" || this.httpError.errorModalStacktrace !== "";
      }

      this.httpErrorModalVisible = true;
    },
    openForbiddenErrorModal(error: any) {
      //reset
      this.forbiddenError.forbiddenModalContent = "";

      this.forbiddenError.forbiddenModalContent = error.data.message;
      this.forbiddenErrorModalVisible = true;
    }
  }
});

import { AdHocOpgaveItem } from "@/models/virksomhed/adHocOpgaveItem";
import { LogItem } from "@/models/virksomhed/logItem";
import moment from "moment";
import { Lookup } from "../lookup";
import { Afgoerelse } from "./afgoerelse";
import { Basis } from "./basis";
import CvrAendringer from "./cvrAendringer";
import { FremmedTjenesteydelse } from "./fremmedtjenesteydelse";
import { Fyringsanlaeg } from "./fyringsanlaeg";
import { Gebyr } from "./gebyr";
import { Haandhaevelse } from "./haandhaevelse";
import { Kontaktperson } from "./kontaktperson";
import Lokalitet from "./lokalitet";
import { Risikovurdering } from "./risikovurdering";
import { Tidslinje } from "./tidslinje";
import { Tidsregistrering } from "./tidsregistrering";
import { Tilsyn } from "./tilsyn";

export enum VIRKSOMHEDS_GRUPPER {
  _1_A = "1a",
  _1_B = "1b"
}

export class NewVirksomhed {
  name: string = "";

  cvrNr: string = "";
  pNr: string = "";
  address: string = "";
  branche: string = "";
  bibrancher: string = "";
  startDato: moment.Moment = moment();

  dawaAdgangsadresseId: string = "";
  dawaAdressetype: string = "";
  etableringsDato: moment.Moment = moment();
}

export default class Virksomhed {
  id: string = "";
  basis: Basis = new Basis();
  kontaktpersonListe: Kontaktperson[] = [];
  lokalitet: Lokalitet = new Lokalitet();
  tilsyn: Tilsyn[] = [];
  haandhaevelser: Haandhaevelse[] = [];
  afgoerelser: Afgoerelse[] = [];
  risikovurderinger: Risikovurdering[] = [];
  tidsregistreringer: Tidsregistrering[] = [];
  fremmedTjenesteydelser: FremmedTjenesteydelse[] = [];
  tidslinje: Tidslinje = new Tidslinje();
  logs: LogItem[] = [];
  adHocOpgaver: AdHocOpgaveItem[] = [];
  wkt: string = "";
  fyringsanlaeget: Fyringsanlaeg[] = [];
  braendselTyper: Fyringsanlaeg[] = [];
  cvrAendringer: CvrAendringer[] = [];
  gebyrer: Gebyr[] = [];

  getVirksomhedsKategori(lookupItems: Lookup[]): string {
    const virksomhedsGruppeId = this.basis.virksomhedsGruppeId;
    const item = lookupItems.find(v => v.id === virksomhedsGruppeId);
    return item !== undefined ? item.navn : "";
  }
}

<template>
  <div data-cy="collapse">
    <div class="header pointer" :class="{ collapsed: !show }" @click="toggle" data-cy="header">
      <label class="h4" :class="{ smallHeader: subCollapseBox }" data-cy="label">{{ titel }}</label>
      <MwIcon class="header-icon" icon="angle-right" :class="{ smallIcon: subCollapseBox }" data-cy="icon" />
    </div>
    <Transition name="slide" @afterEnter="onAfterEnter" @afterLeave="onAfterLeave">
      <div v-show="show" data-cy="slide">
        <div class="hr top" v-if="showTopHr" data-cy="topHr"></div>
        <slot></slot>
        <div class="hr" v-if="showBottomHr" data-cy="bottomHr"></div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { ref, withDefaults } from "vue";
import MwIcon from "./MwIcon.vue";

interface Props {
  titel?: string;
  collapsed?: boolean;
  showBottomHr?: boolean;
  showTopHr?: boolean;
  subCollapseBox?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  titel: "",
  collapsed: false,
  showBottomHr: false,
  showTopHr: false,
  subCollapseBox: false
});

const emit = defineEmits<{
  (eventName: "afterEnter"): void;
  (eventName: "afterLeave"): void;
}>();

const show = ref(!props.collapsed);

function onAfterLeave() {
  emit("afterLeave");
}

function onAfterEnter() {
  emit("afterEnter");
}

function toggle() {
  show.value = !show.value;
}
</script>

<style scoped>
/** animation start */

.slide-enter-active {
  transition: 0.3s ease-in;
}

.slide-leave-active {
  transition: 0.3s ease-out;
}

.slide-enter-to,
.slide-leave {
  max-height: 400px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

/** animation end */

.header {
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.header.collapsed .header-icon {
  transform: rotate(0deg);
  transition-duration: 0.3s;
}

.header .header-icon {
  transform: rotate(90deg);
  transition-duration: 0.3s;
  margin-left: 12px;
  font-size: 24px;
  top: 2px;
  position: relative;
}

.h4 {
  margin-bottom: 0px;
}

.smallHeader {
  font-size: 1.11rem;
  line-height: 0;
}

.smallIcon {
  font-size: 20px !important;
  margin-left: 10px !important;
}
</style>

import moment from "moment";
import { SATResultatKategoriEnum } from "./satResultatKategoriEnum";

export class SATResultat {
  id: string = "";
  satVirksomhedId?: string;
  virksomhedId?: string;
  overfoertTilMiljoestyrelsenDato?: moment.Moment;
  oprettetMiljoestyrelsenDato?: moment.Moment;

  satTilsynsperiodeId?: string;
  satTilsynsresultatKategori?: SATResultatKategoriEnum;
  satTilsynsresultatTypeId?: string;
  satTilsynsresultatType?: string;
  resultatTypeBemaerkning?: string;
  timeantal?: number;

  udfoertDato?: moment.Moment;
  sagsbehandlerBrugerId?: string;

  affaldsfraktion_Madaffald?: boolean;
  affaldsfraktion_Papir?: boolean;
  affaldsfraktion_Pap?: boolean;
  affaldsfraktion_Glas?: boolean;
  affaldsfraktion_Metal?: boolean;
  affaldsfraktion_Plastic?: boolean;
  affaldsfraktion_Madogdrikkekarton?: boolean;
  affaldsfraktion_Farligtaffald?: boolean;
  affaldsfraktion_Tekstiler?: boolean;
  affaldsfraktion_Restaffald?: boolean;
  affaldsfraktion_Haveaffald?: boolean;
  affaldsfraktion_Klinisk?: boolean;
  affaldsfraktion_Elektronik?: boolean;
  affaldsfraktion_Trae?: boolean;
  affaldsfraktion_Pvc?: boolean;
  affaldsfraktion_Byggeanlaegsaffald?: string;
  affaldsfraktion_Speciel?: string;
  affaldsfraktion_Andet?: string;

  forkerteFraktionerIMstData?: boolean;
  forkerteFraktionerIMstDataBemaerkning?: string;
  forkerteAktoererIMstData?: boolean;
  forkerteAktoererIMstDataBemaerkning?: string;
}

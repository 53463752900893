<template>
  <div class="form-group" :class="{ 'has-danger': validations?.$invalid }">
    <div class="d-flex justify-content-between" v-if="currentMax != null && label">
      <label :for="tempId">
        <span v-html="label"></span>
        {{ isRequired && !disableValidation ? "*" : "" }}
        <span v-if="dmaValidation" class="externallyRequired pink-dark-color"> *<sup>DMA</sup> </span>
        <span v-else-if="jupiterValidation" class="externallyRequired pink-dark-color"> *<sup>Jupiter</sup></span>
        <span v-else-if="satValidation" class="externallyRequired pink-dark-color"> *<sup>SAT</sup></span>
      </label>
      <label
        data-cy="input-textLength"
        :for="tempId"
        :class="{
          'dark-gray-lighter-color': currentLength !== currentMax,
          'black-ich-color': currentLength === currentMax
        }"
      >
        {{ currentLength }}/{{ currentMax }}
      </label>
    </div>

    <div v-else-if="label">
      <label :for="tempId">
        <span v-html="label" v-if="label"></span>
        {{ isRequired && !disableValidation ? "*" : "" }}
        <span v-if="dmaValidation" data-cy="input-dmaValidation" class="externallyRequired pink-dark-color">
          *<sup>DMA</sup>
        </span>
        <span
          v-else-if="jupiterValidation"
          data-cy="input-jupiterValidation"
          class="externallyRequired pink-dark-color"
        >
          *<sup>Jupiter</sup>
        </span>
        <span v-else-if="satValidation" data-cy="input-jupiterValidation" class="externallyRequired pink-dark-color">
          *<sup>SAT</sup></span
        >
      </label>
    </div>
    <div
      class="inputField"
      :class="{ externalField: isExternalField, hasInfoButton: hasInfoButton }"
      :style="{ width: width }"
    >
      <p v-if="dmaField || dmaValidation" data-cy="input-dmaIndberet" title="Indberettes til DMA">D</p>
      <p
        v-if="jupiterField || jupiterValidation"
        data-cy="input-jupiterIndberet"
        :title="jupiterShowOnly ? 'Hentes fra Jupiter' : 'Indberettes til Jupiter'"
      >
        J
      </p>
      <p v-if="satField || satValidation" data-cy="input-satIndberet" title="Indberettes til SAT">S</p>
      <input
        class="form-control"
        :id="tempId"
        autocomplete="off"
        spellcheck="false"
        :placeholder="placeholder"
        :value="modelValue"
        :label="label"
        :disabled="disabled"
        @blur="blur"
        @input="input($event)"
        @focus="format()"
        :maxlength="currentMax"
        :step="step"
        :min="min"
        :max="max"
        :type="type"
        @keypress="isDotPrevented($event)"
      />

      <MwInfoIcon
        v-if="infoButtonOptions"
        :header="infoButtonOptions.header"
        :content="infoButtonOptions.content"
        :icon="infoButtonOptions.icon"
        class="ml-2 infoIconButton"
        :disabled="false"
      ></MwInfoIcon>

      <a :href="'mailto:' + modelValue" target="_blank" v-if="type === 'email'">
        <i
          v-if="type === 'email' && disabled"
          class="far fa-envelope fa-lg position-absolute rightAlignedIcon pointer"
          title="Tryk for at sende en e-mail"
        ></i>
      </a>
      <a v-if="removeLink" data-cy="input-removeLink" @click="removeLinkClicked">
        <i
          data-cy="input-removeLinkIcon"
          class="fa fa-times fa-lg position-absolute rightAlignedIcon2 pointer"
          title="Tryk for at fjerne"
          :class="{ editLinkOffset: !editLink, externalFieldOffset: jupiterField || dmaField }"
        ></i>
      </a>
      <a v-if="editLink" @click="editLinkClicked">
        <i
          data-cy="input-editLinkIcon"
          class="fa fa-edit fa-lg position-absolute rightAlignedIcon pointer"
          title="Tryk for at ændre"
          :class="{ externalFieldOffset: jupiterField || dmaField }"
        ></i>
      </a>
    </div>
    <div class="form-control-feedback" data-cy="input-error" v-if="hasErrors">{{ lastError }}</div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, inject, Ref, ref } from "vue";
  import { useValidations } from "@/composables/useValidations";
  import { createGuid } from "@/utils/utils";
  import { ChangeTrackerSetChangedFunction } from "@/components/mwUtils/changeTracker/ChangeTracker";
  import MwInfoIcon from "@/components/mwUtils/MwInfoIcon.vue";

  interface Props {
    modelValue?: any;
    placeholder?: string;
    disabled?: boolean;
    focus?: boolean;
    label?: string;
    type?: string;
    step?: string;
    min?: number;
    max?: number;
    validations?: any; //vuelidate.Params;
    dmaValidation?: boolean;
    editLink?: boolean;
    removeLink?: boolean;
    dmaField?: boolean;
    jupiterValidation?: boolean;
    jupiterField?: boolean;
    jupiterShowOnly?: boolean;
    width?: string;
    ignoreChangeTracking?: boolean;
    preventDot?: false;
    infoButtonOptions?: any;
    satValidation?: boolean;
    satField?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    text: "",
    placerholder: "",
    disabled: false,
    focus: false,
    label: "",
    type: "text",
    step: "",
    validations: null,
    dmaValidation: false,
    editLink: false,
    removeLink: false,
    dmaField: false,
    jupiterValidation: false,
    jupiterfield: false,
    jupiterShowOnly: false,
    width: "100%",
    ignoreChangeTracking: false,
    preventDot: false,
    satValidation: false
  });

  const emit = defineEmits<{
    (eventName: "update:modelValue", value: string): void;
    (eventName: "removeLinkClicked"): void;
    (eventName: "editLinkClicked"): void;
    (eventName: "blur"): void;
  }>();

  const tempId: string = "input" + createGuid();
  const disableValidation: Ref<boolean> = ref(false);

  const hasInfoButton = computed(() => {
    return (
      props.infoButtonOptions !== undefined &&
      props.infoButtonOptions.content &&
      props.infoButtonOptions.content.length > 0
    );
  });
  function isDotPrevented(event: KeyboardEvent) {
    if (props.preventDot && event.key === ".") {
      event.preventDefault();
    }
  }

  function format() {
    if (props.preventDot) {
      const newValue = props.modelValue.toString().replaceAll(",", "").replaceAll(".", "");
      emit("update:modelValue", newValue);
    }
  }

  function removeLinkClicked() {
    emit("removeLinkClicked");
  }

  function editLinkClicked() {
    emit("editLinkClicked");
  }

  const setTrackerChanged = inject<ChangeTrackerSetChangedFunction>("setChanged", () => null);

  function blur() {
    emit("blur");
  }

  const currentLength = computed(() => (props.modelValue ? props.modelValue.length : 0));

  function input(event: any) {
    emit("update:modelValue", event.target.value);

    if (setTrackerChanged) {
      setTrackerChanged();
    }
  }

  function setDisableValidation(disable: boolean) {
    //Hvis vi ønsker at fjerne valideringen (og dermed stjernen) på baggrund af et andet input, er det tilsyneladende ikke muligt at fjerne en validation fra opsætningen når vi bruger typescript
    //i opsætningen kan man ikke tilgå komponent instansen, og i komponent instancen er der ikke nogen officielt vuelidate måde at tilføje/fjerne en validering. Det er måske muligt med et grimt hack
    //men så hellere denne løsning.
    disableValidation.value = disable;
  }

  const isExternalField = computed(() => {
    return (
      props.dmaValidation ||
      props.dmaField ||
      props.jupiterValidation ||
      props.jupiterField ||
      props.satField ||
      props.satValidation
    );
  });

  const { hasErrors, lastError, isRequired, currentMax } = useValidations(props.validations);
</script>

<style scoped>
  .rightAlignedIcon {
    top: 50%;
    translate: 0 -50%;
    right: 25px;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s ease-out;
    color: #b586a4;
  }
  .rightAlignedIcon2 {
    top: 50%;
    translate: 0 -50%;
    right: 55px;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s ease-out;
    color: #b586a4;
  }
  .rightAlignedIcon.externalFieldOffset {
    top: 12px;
    right: 20px;
  }
  .rightAlignedIcon2.externalFieldOffset {
    top: 13px;
  }
  .rightAlignedIcon2.editLinkOffset {
    right: 20px;
  }
  .inputField {
    display: flex;
  }

  .inputField:hover .rightAlignedIcon,
  .inputField:hover .rightAlignedIcon2 {
    opacity: 1;
  }

  .inputField > input:focus {
    outline: none;
    background-color: #f9f9f9;
  }

  .inputField.externalField {
    position: relative;
  }
  .inputField.externalField:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    display: block;
    border-left: 27px solid transparent;
    border-bottom: 27px solid transparent;
    border-top: 27px solid #8cb58a;
    z-index: 0;
    opacity: 0.7;
    pointer-events: none;
  }
  .inputField.externalField p {
    position: absolute;
    font-size: 0.73rem;
    right: 0px;
    color: #f3f3f3;
    z-index: 1;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    cursor: default;
  }

  .inputField.externalField.hasInfoButton:after {
    right: 40px;
  }

  .inputField.externalField.hasInfoButton p {
    right: 40px;
  }

  .infoIconButton :deep(button) {
    height: 40px;
  }
</style>

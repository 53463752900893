import { Gebyr } from "@/models/virksomhed/gebyr";
import moment from "moment";
import { SATResultat } from "./SATResultat";

export default class SATVirksomhed {
  id?: string;
  satTilsynsperiodeId?: string;
  fraSatListe?: boolean;
  satVirksomhedKanAendres?: boolean;
  virksomhedsnavn?: string; //max-length 255
  udvalgtDato?: moment.Moment;
  deadlineDato?: moment.Moment;
  cvrNr?: number;
  pNr?: number;
  risikoscore?: number; //range 1--5
  udvaelgelsesmetode?: string; //max-len 100
  satStatus?: string;
  satResultat: SATResultat = new SATResultat();
  gebyrer?: Gebyr[] 
}

<template>
  <div class="form-group row">
    <div class="col-6">
      <MwSelect v-bind="editorProps.selectProps" v-model="modelValueShadow.value" :disabled="loading"></MwSelect>
      <div style="color: #821307" v-if="editorProps.errorMessage">{{ editorProps.errorMessage }}</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import MwSelect from "@/components/mwUtils/mwInputs/MwSelect.vue";
import { onMounted, reactive, ref, toRaw, watch } from "vue";

interface Props {
  modelValue: { value: any };
  editorProps: {
    selectProps: any;
    mounted?: () => void;
    errorMessage?: string;
  };
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (eventName: "update:modelValue", value: any): void;
}>();

const modelValueShadow = reactive(props.modelValue);
watch(
  modelValueShadow,
  v => {
    emit("update:modelValue", toRaw(v));
  },
  { deep: true }
);

const loading = ref(false);

onMounted(async () => {
  if (props.editorProps.mounted) {
    loading.value = true;
    await props.editorProps.mounted();
    loading.value = false;
  }
});
</script>
<style scoped></style>

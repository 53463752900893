<template>
  <div class="form-group" :class="{ 'has-danger': validations?.$invalid, 'float-right': floatRight }">
    <label v-if="label && label.length > 0" :for="inputFieldId">
      {{ label }}{{ isRequired ? " *" : "" }}<span v-if="dmaValidation" class="externallyRequired pink-dark-color">*<sup>DMA</sup></span>
      <span v-else-if="jupiterValidation" class="externallyRequired pink-dark-color">*<sup>Jupiter</sup></span>
    </label>
    <div class="input-group-prepend form-group mb-0">
      <span class="input-group-text datepickerCalendarIcon" :class="{ 'd-none': invisible }" @click="open = true">
        <MwIcon icon="calendar" :class="{ disabled: disabled }" />
      </span>
      <div class="inputField" :class="{ externalField: isExternalField }">
        <p v-if="dmaField || dmaValidation" title="Indberettes til DMA">D</p>
        <p v-if="jupiterField || jupiterValidation" :title="jupiterShowOnly ? 'Hentes fra Jupiter' : 'Indberettes til Jupiter'">J</p>
        <p v-if="satField || satValidation" title="Indberettes til SAT">S</p>

        <DatePicker
          @input="input($event)"
          :input-attr="{ id: inputFieldId }"
          :value="mwLocalDate"
          @update:value="onValueUpdate"
          :formatter="format"
          :title-format="titleFormat"
          :type="type"
          :input-class="{ 'form-control d-none': invisible, 'form-control': !invisible }"
          v-model:open="open"
          :placeholder="disabled ? '' : props.placeholder"
          :disabled="disabled"
          :disabled-date="isDateDisabled"
          :lang="lang"
          :show-week-number="true"
          ref="calendar"
          :append-to-body="false"
        >
          <template v-slot:icon-calendar><span></span></template>
          <template v-slot:icon-clear><i class="fal fa-times mr-2"></i></template>
        </DatePicker>
      </div>
      <MwInfoIcon
        v-if="infoButtonOptions"
        :header="infoButtonOptions.header"
        :content="infoButtonOptions.content"
        :icon="infoButtonOptions.icon"
        class="ml-2 infoIconButton"
      ></MwInfoIcon>
    </div>
    <div class="form-control-feedback" v-if="hasErrors">{{ lastError }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, nextTick, Ref, ref, watch } from "vue";
import moment from "moment";
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";

import MwInfoIcon from "@/components/mwUtils/MwInfoIcon.vue";
import { createGuid } from "@/utils/utils";
import { useValidations } from "@/composables/useValidations";
import MwIcon from "@/components/mwUtils/MwIcon.vue";
import { ChangeTrackerSetChangedFunction } from "@/components/mwUtils/changeTracker/ChangeTracker";

class DisabledDates {
  from: Date | null = null;
  to: Date | null = null;
}

interface Props {
  //TODO Typen er sat til "any" fordi vue spammer dev tool konsollen med en masse warning fordi den ikke forstår typen moment.Moment
  modelValue?: any; //moment.Moment | Date;
  placeholder?: string;
  disabled?: boolean;
  validations?: any;
  label?: string;
  floatRight?: boolean;
  disabledDates?: DisabledDates;
  dmaValidation?: boolean;
  openCalendar?: boolean;
  dmaField?: boolean;
  jupiterValidation?: boolean;
  jupiterShowOnly?: boolean;
  jupiterField?: boolean;
  ignoreChangeTracking?: boolean;
  invisible?: boolean;
  type?: string;
  infoButtonOptions?: Record<string, any>;
  satValidation?: boolean;
  satField?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => moment(),
  placeholder: "Vælg dato",
  disabled: false,
  label: "",
  floatRight: false,
  dmaValidation: false,
  openCalendar: false,
  dmaField: false,
  jupiterValidation: false,
  jupiterShowOnly: false,
  jupiterField: false,
  ignoreChangeTracking: false,
  invisible: false,
  type: "date"
});

const emit = defineEmits<{
  (eventName: "input", value: any): void;
  (eventName: "update:modelValue", value: moment.Moment | null): void;
}>();

const inputFieldId: string = "datepicker-" + createGuid();
const mwLocalDate: Ref<Date | null> = ref(null);
const validationError: string = "";
const open = ref(false);

const lang: any = {
  formatLocale: {
    months: ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"],
    monthsShort: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
    weekdays: ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"],
    weekdaysShort: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
    weekdaysMin: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
    firstDayOfWeek: 1,
    firstWeekContainsDate: 4
  },
  yearFormat: "YYYY",
  monthFormat: "MMM",
  monthBeforeYear: true
};
// const lang: any = {
//   formatLocale: {
//     monthsShort: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"]
//   }
// };

watch(
  () => props.openCalendar,
  () => {
    open.value = true;
  }
);

watch(
  () => props.modelValue,
  (newValue: moment.Moment | null) => {
    if (typeof newValue === "string") {
      newValue = moment(newValue);
    }
    mwLocalDate.value = newValue != null && newValue != undefined ? newValue.toDate() : null;
  },
  {
    deep: true,
    immediate: true
  }
);

const setTrackerChanged = inject<ChangeTrackerSetChangedFunction>("setChanged", () => null);

function onValueUpdate(date: Date | null) {
  const value = date !== null ? moment(date) : null;

  emit("update:modelValue", value);

  if (setTrackerChanged) {
    setTrackerChanged();
  }
}

function input(date: any) {
  const value = date !== null ? moment(date) : null;
  emit("input", value);

  if (setTrackerChanged) {
    setTrackerChanged();
  }
}

const format = computed(() => {
  //Vi overrider datepickerens format metode, så man ikke kan indtaste alt muligt bavl der bliver parset korrekt. F.eks. kan man indtaste 40-02-2020 som vil blive parset til 10-03-2020
  //Vi undgår problemet ved at parse datoen med moment i strict mode (true parameteren)
  //Dette kan vi dog ikke gøre ved datetime´s pga. en bug i datepickeren; for hvis ikke man sætter format prop til en streng ('DD-MM-YYYY HH:mm'), men i stedet denne metode, så vises sekundvælger
  //i selve time pickeren, hvilket vi ikke ønsker. Tror at den bruger denne streng til noget andet internt, som så ikke bliver sat når format sættes som et objekt.
  return {
    // Date to String
    stringify: (date: Date) => {
      if (date) {
        return props.type === "datetime" ? moment(date).format("DD-MM-YYYY HH:mm") : moment(date).format("DD-MM-YYYY");
      } else {
        return "";
      }
    },
    // String to Date
    parse: (value: string) => {
      if (value) {
        if (moment(value, "DD-MM-YYYY", true).format().toLowerCase() === "invalid date") {
          emit("input", null);
          nextTick(() => {
            validateInput();
          });
          return null;
        }
        return moment(value, "DD-MM-YYYY", true).toDate();
      } else {
        props.value = null;
        return null;
      }
    }
  };
});

const titleFormat = computed(() => {
  return props.type === "datetime" ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY";
});

const isExternalField = computed(() => {
  return props.dmaValidation || props.dmaField || props.jupiterValidation || props.jupiterField || props.satField || props.satValidation;
});

const { hasErrors, lastError, isRequired } = useValidations(props.validations);

function isDateDisabled(date: any) {
  if (props.disabledDates) {
    if (props.disabledDates.from && props.disabledDates.to) {
      if (props.disabledDates.to >= props.disabledDates.from) {
        return date >= props.disabledDates.from && date <= props.disabledDates.to;
      } else {
        return date >= props.disabledDates.from || date <= props.disabledDates.to;
      }
    }
    if (props.disabledDates.from) {
      return date >= props.disabledDates.from;
    }
    if (props.disabledDates.to) {
      return date <= props.disabledDates.to;
    }
  }
  return false;
}
</script>

<style>
.mx-datepicker {
  width: 100%;
}
.mx-calendar {
  width: 350px;
}
.mx-date-time {
  width: 350px;
}
.mx-table-date td,
.mx-table-date th {
  font-size: 15.2px;
}
.mx-btn {
  color: #3a3a3a;
}
.mx-btn:hover {
  border-color: #000;
  color: #000;
}
.mx-calendar-content .cell:hover {
  color: #fff;
  background-color: #bfbfc1;
}

.mx-table-date .today {
  color: #3a3a3a;
  background-color: #ebeae5;
}

.mx-calendar-content {
  height: 267px;
}

.mx-calendar-content .cell.active {
  background-color: #999fa7;
}

.mx-calendar-content .cell {
  cursor: pointer;
  vertical-align: middle;
}

.mx-calendar button:focus {
  outline: none;
}

.mx-week-number {
  vertical-align: middle;
  color: #a4852f;
}

.mx-btn {
  font-size: 15.2px;
}

.mx-datepicker-body {
  font-family: SwecoSans-Regular;
}

.mx-time-column .mx-time-item {
  font-size: 16px;
}
.mx-time-content {
  height: 280px;
}
.mx-time-column .mx-time-list::after {
  height: 0px;
}
</style>

<style scoped>
.datepickerCalendarIcon:hover {
  cursor: pointer;
}
.datepickerCalendarIcon i.disabled {
  opacity: 0.7;
}

.inputField.externalField {
  position: relative;
}
.inputField.externalField:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  display: block;
  border-left: 27px solid transparent;
  border-bottom: 27px solid transparent;
  border-top: 27px solid #8cb58a;
  z-index: 0;
  opacity: 0.7;
  pointer-events: none;
}
.inputField.externalField p {
  position: absolute;
  font-size: 0.73rem;
  right: 0px;
  color: #f3f3f3;
  z-index: 1;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  margin-bottom: 0px;
  cursor: default;
}

.infoIconButton :deep(button) {
  height: 40px;
}
</style>

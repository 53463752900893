import { SATResultatKategoriEnum } from '@/models/SAT/satResultatKategoriEnum';
import { SATResultatTypeEnum } from '@/models/SAT/satResultatTypeEnum';
import { Lookup } from '@/models/lookup';
import { useSessionStore } from '@/stores/session';
import { LookupState } from '@/stores/statetypes';
import { fetchLookup } from '@/utils/utils';
import { defineStore } from 'pinia';

export const useLookupStore = defineStore('lookup', {
  state: (): LookupState => {
    return {
      loaded: false,

      aktiviteter: [],
      sagsbehandler: [],
      kontaktpersonTyper: [],
      kontaktpersonBetalerTyper: [],

      virksomhedsgrupper: [],
      miljoemyndigheder: [],
      primaereDyreTyper: [],
      dmaStatus: [],

      dmaDokumenttype: [],
      dmaFortrolighedsNiveau: [],
      batKonklusioner: [],

      tilsynTyper: [],
      tilsynBaggrunde: [],
      tilsynVedroererAnlaegstyper: [],
      tilsynVedroererKrav: [],
      tilsynKategorier: [],

      miljoeRisikoVurderinger: [],

      afgoerelseStatusser: [],
      afgoerelseVedrAnlaegsTyper: [],
      afgoerelseResultater: [],
      afgoerelseBATAEL: [],

      haandhaevelseStatusser: [],
      haandhaevelseTyper: [],
      haandhaevelseVedroererAnlaegstyper: [],
      haandhaevelseVedroererKrav: [],
      haandhaevelseMiljoeForhold: [],

      risikoscoreTyper: [],

      fyringsanlaegsTyper: [],
      fyringsanlaegNaceKoder: [],
      fyringsanlaegVedroererNaceKoder: [],
      fyringsanlaegAnlaegsTyper: [],
      fyringsanlaegNoedanlaegsTyper: [],
      fyringsanlaegBraendselsTyper: [],
      fyringsanlaegBraendselsKategorier: [],

      brugerbetalingTidsregistreringsKategorier: [],
      brugerbetalingFremmedTjenesteydelseKategorier: [],

      pladserPladsTyperLandbrug: [],
      pladserPladsTyperVirksomheder: [],
      pladserArtifaktStatusser: [],
      pladserSideafskaermninger: [],

      beholdereOverdaekningsTyper: [],

      beholdereBeholderTyperLandbrug: [],
      beholdereBeholderTyperVirksomheder: [],

      beholdereArtifaktStatusser: [],

      dyreholdStatusser: [],
      dyreholdOrdninger: [],
      dyreholdArtifaktStatusser: [],
      dyreholdStaldsystemer: [],
      dyreholdStaldtyper: [],
      dyreholdDyreTyper: [],

      templateEmner: [],

      adHocEmner: [],

      fordringstyper: [],

      planlaegningYears: [],

      satTilsynsperioder: [],
      gebyrTyper: [],
      tidsregistreringsKategorier: [],
      satResultatKategorier: [],
      satResultatTyper: [],
    };
  },
  actions: {
    updateLookupState(data: any, key: string) {
      this[key] = data;
    },
    async fetchLookups() {
      await Promise.all([fetchLookup('MWLandbrugLookUp/list/primaerdyretype', 'primaerdyretype')]);
    },
    async fetchLandbrugLookups() {
      await Promise.all([
        fetchLookup('MWLandbrugLookUp/list/primaerdyretype', 'primaereDyreTyper'),
        fetchLookup('dmalookup/virksomhedsgrupper', 'virksomhedsgrupper'),
      ]);
    },
    async fetchVirksomhederLookups() {
      await Promise.all([fetchLookup('dmalookup/virksomhedsgrupper', 'virksomhedsgrupper')]);
    },
    async fetchAdHocOpgaverLookups() {
      await Promise.all([fetchLookup('AdHocOpgave/emner', 'adHocEmner')]);
    },
    async fetchBasisLookups({ kundeId = '', virksomhedstype = 'vandanlaeg' }) {
      await Promise.all([
        fetchLookup('dmalookup/aktiviteter', 'aktiviteter'),
        fetchLookup('dmalookup/batkonklusion', 'batKonklusioner'),
        fetchLookup('dmalookup/virksomhedsgrupper', 'virksomhedsgrupper'),
        fetchLookup(
          'sagsbehandler/list/' + kundeId + '?type=' + virksomhedstype,
          'sagsbehandler',
          true
        ),
        fetchLookup('dmalookup/miljoemyndighed', 'miljoemyndigheder'),
        fetchLookup('dmalookup/dmaStatus', 'dmaStatus'),
        fetchLookup('MWLandbrugLookUp/list/primaerdyretype', 'primaereDyreTyper'),
      ]);
    },

    async fetchAfgoerelserLookups({ kundeId, virksomhedstype }: any) {
      await Promise.all([
        fetchLookup('dmalookup/afgoerelseVedroererAnlaegstyper', 'afgoerelseVedrAnlaegsTyper'),
        fetchLookup('dmalookup/afgoerelsesStatus', 'afgoerelseStatusser'),
        fetchLookup('dmalookup/afgoerelseBATAEL', 'afgoerelseBATAEL'),
        fetchLookup('dmalookup/resultatAfgoerelse', 'afgoerelseResultater'),
        fetchLookup('dmalookup/dmaDokumenttype', 'dmaDokumenttype'),
        fetchLookup('dmalookup/dmaFortrolighedsNiveau', 'dmaFortrolighedsNiveau'),
        fetchLookup(
          'sagsbehandler/list/' + kundeId + '?type=' + virksomhedstype,
          'sagsbehandler',
          true
        ),
      ]);
    },

    async fetchHaandhaevelseLookups({ kundeId, virksomhedstype }: any) {
      await Promise.all([
        fetchLookup('dmalookup/tilsynsTyper', 'tilsynTyper'),
        fetchLookup('dmalookup/tilsynsKategorier', 'tilsynKategorier'),
        fetchLookup('dmalookup/haandhaevelsesStatusser', 'haandhaevelseStatusser'),
        fetchLookup('dmalookup/haandhaevelsesTyper', 'haandhaevelseTyper'),
        fetchLookup('dmalookup/miljoeForhold', 'haandhaevelseMiljoeForhold'),
        fetchLookup('dmalookup/haandhaevelsesVedroererKrav', 'haandhaevelseVedroererKrav'),
        fetchLookup(
          'dmalookup/haandhaevelsesVedroererAnlaegstype',
          'haandhaevelseVedroererAnlaegstyper'
        ),
        fetchLookup(
          'sagsbehandler/list/' + kundeId + '?type=' + virksomhedstype,
          'sagsbehandler',
          true
        ),
      ]);
    },
    async fetchFyringsanlaegLookups() {
      await Promise.all([
        fetchLookup('dmalookup/anlaegsTyper', 'fyringsanlaegAnlaegsTyper'),
        fetchLookup('dmalookup/fyringsAnlaegsTyper', 'fyringsanlaegsTyper'),
        fetchLookup('dmalookup/fyringsanlaegVedroererNACEKode', 'fyringsanlaegVedroererNaceKoder'),
        fetchLookup('dmalookup/naceKoder', 'fyringsanlaegNaceKoder'),
        fetchLookup('dmalookup/noedanlaegstype', 'fyringsanlaegNoedanlaegsTyper'),
        fetchLookup('dmalookup/fyringsAnlaegBraendselsTyper', 'fyringsanlaegBraendselsTyper'),
        fetchLookup(
          'dmalookup/fyringsanlaegBraendselskategorier',
          'fyringsanlaegBraendselsKategorier'
        ),
      ]);
    },

    //specifik for landbrug
    async fetchPladserLookupsLandbrug() {
      await Promise.all([
        fetchLookup('mwlandbruglookup/list/pladstyperlandbrug', 'pladserPladsTyperLandbrug'),
        fetchLookup('mwlandbruglookup/list/artifaktstatusser', 'pladserArtifaktStatusser'),
        fetchLookup('mwlandbruglookup/list/sideafskaermninger', 'pladserSideafskaermninger'),
      ]);
    },

    //specifik for virksomheder:
    async fetchPladserLookupsVirksomheder() {
      await Promise.all([
        fetchLookup(
          'mwlandbruglookup/list/pladstypervirksomheder',
          'pladserPladsTyperVirksomheder'
        ),
        fetchLookup('mwlandbruglookup/list/artifaktstatusser', 'pladserArtifaktStatusser'),
        fetchLookup('mwlandbruglookup/list/sideafskaermninger', 'pladserSideafskaermninger'),
      ]);
    },

    async fetchDyreholdLookups() {
      await Promise.all([
        fetchLookup('mwlandbruglookup/list/dyreholdstatus', 'dyreholdStatusser'),
        fetchLookup('mwlandbruglookup/list/dyreholdordning', 'dyreholdOrdninger'),
        fetchLookup('mwlandbruglookup/list/artifaktstatusser', 'dyreholdArtifaktStatusser'),
        fetchLookup('mwlandbruglookup/list/dyretypestaldsystem', 'dyreholdStaldsystemer'),
        fetchLookup('mwlandbruglookup/list/staldtype', 'dyreholdStaldtyper'),
        fetchLookup('dyretype/list', 'dyreholdDyreTyper'),
      ]);
    },

    async fetchBeholderLookupsLandbrug() {
      await Promise.all([
        fetchLookup(
          'mwlandbruglookup/list/beholdertyperlandbrug',
          'beholdereBeholderTyperLandbrug'
        ),
        fetchLookup('mwlandbruglookup/list/artifaktstatusser', 'beholdereArtifaktStatusser'),
        fetchLookup('mwlandbruglookup/list/overdaekningstyper', 'beholdereOverdaekningsTyper'),
      ]);
    },

    async fetchBeholderLookupsVirksomheder() {
      await Promise.all([
        fetchLookup(
          'mwlandbruglookup/list/beholdertypervirksomheder',
          'beholdereBeholderTyperVirksomheder'
        ),
        fetchLookup('mwlandbruglookup/list/artifaktstatusser', 'beholdereArtifaktStatusser'),
        fetchLookup('mwlandbruglookup/list/overdaekningstyper', 'beholdereOverdaekningsTyper'),
      ]);
    },

    async fetchKontaktpersonLookups() {
      await Promise.all([
        fetchLookup('kontaktpersontype/list', 'kontaktpersonTyper'),
        fetchLookup('Kontaktperson/kontaktpersonbetalertypelist', 'kontaktpersonBetalerTyper'),
      ]);
    },

    async fetchTilsynLookups({ kundeId, virksomhedstype, virksomhedId }: any) {
      await Promise.all([
        fetchLookup('dmalookup/tilsynsTyper', 'tilsynTyper'),
        fetchLookup('dmalookup/tilsynsKategorier', 'tilsynKategorier'),
        fetchLookup('dmalookup/tilsynsBaggrunde', 'tilsynBaggrunde'),
        fetchLookup('dmalookup/tilsynsVedroererAnlaegstyper', 'tilsynVedroererAnlaegstyper'),
        fetchLookup('dmalookup/tilsynVedroererKrav', 'tilsynVedroererKrav'),
        fetchLookup('dmalookup/miljoeForhold', 'haandhaevelseMiljoeForhold'),
        fetchLookup('dmalookup/dmaDokumenttype', 'dmaDokumenttype'),
        fetchLookup('dmalookup/dmaFortrolighedsNiveau', 'dmaFortrolighedsNiveau'),
        fetchLookup(
          'miljoeRisikoVurdering/list/' + virksomhedId + '/lookup',
          'miljoeRisikoVurderinger',
          true
        ),
        fetchLookup(
          'sagsbehandler/list/' + kundeId + '?type=' + virksomhedstype,
          'sagsbehandler',
          true
        ),
      ]);
    },

    async fetchRisikovurderingLookups() {
      await Promise.all([
        fetchLookup('dmalookup/risikoscoretype', 'risikoscoreTyper'),
        fetchLookup('dmalookup/virksomhedsgrupper', 'virksomhedsgrupper'),
      ]);
    },

    async fetchTidsregistreringLookups({ kundeId, virksomhedId, virksomhedstype }: any) {
      await Promise.all([
        fetchLookup(
          'tidsregistreringsKategori/listVirksomhed?virksomhedid=' + virksomhedId,
          'brugerbetalingTidsregistreringsKategorier',
          true
        ),
        fetchLookup(
          'sagsbehandler/list/' + kundeId + '?type=' + virksomhedstype,
          'sagsbehandler',
          true
        ),
      ]);
    },
    async fetchFremmedTjenesteydelseLookups({ kundeId, virksomhedId, virksomhedstype }: any) {
      await Promise.all([
        fetchLookup(
          'tidsregistreringsKategori/listVirksomhed?virksomhedid=' + virksomhedId,
          'brugerbetalingFremmedTjenesteydelseKategorier',
          true
        ),
        fetchLookup(
          'sagsbehandler/list/' + kundeId + '?type=' + virksomhedstype,
          'sagsbehandler',
          true
        ),
      ]);
    },

    async fetchAarsplanlaegningLookups() {
      await Promise.all([fetchLookup('dmalookup/tilsynsKategorier', 'tilsynKategorier')]);
    },

    async fetchTilsynDyreholddeLookups() {
      await Promise.all([
        fetchLookup('mwlandbruglookup/list/staldtype', 'dyreholdStaldtyper'),
        fetchLookup('dmalookup/tilsynsKategorier', 'dyreholdDyreTyper'),
      ]);
    },

    async fetchKontrolpunktsskabelonerLookups() {
      await Promise.all([fetchLookup('Kontrolpunkt/henttemplateemner', 'templateEmner')]);
    },

    async fetchTilsynsskabelonerLookups() {
      await Promise.all([fetchLookup('TilsynRapport/henttemplateemner', 'templateEmner')]);
    },

    async fetchFakturaforslagLookups() {
      const sessionStore = useSessionStore();

      const kundeId = sessionStore.activeKunde.kundeId;
      await Promise.all([
        fetchLookup(
          'sagsbehandler/list/' + kundeId + '?type=virksomhedlandbrug',
          'sagsbehandler',
          true
        ),
        fetchLookup('brugerbetaling/GetPSRMFordringstypeLookup', 'fordringstyper', true),
      ]);
    },
    async fetchPlanlaegningsLookups() {
      const sessionStore = useSessionStore();

      const kundeId = sessionStore.activeKunde.kundeId;
      await Promise.all([
        fetchLookup('PlanlaegningsPeriode/planlaegningYears/' + kundeId, 'planlaegningYears', true),
      ]);
    },

    async fetchSATLookups(virksomhedstype: string) {
      const sessionStore = useSessionStore();
      const kundeId = sessionStore.activeKunde.kundeId;

      await Promise.all([
        fetchLookup(`SATTilsynsperiode/tilsynsperiodelookup/${kundeId}`, 'satTilsynsperioder'),
        fetchLookup(`SATResultatType/lookup`, 'satResultatTyper'),

        //OBS: brugere med SagsbehandlerAffald rollen (dvs. både ifm. SAT virkosmheder og affaldstilsyn for eksisterende MW virksomheder)
        fetchLookup(
          'sagsbehandler/list/' + kundeId + `?type=${virksomhedstype}`,
          'sagsbehandler',
          true
        ),
      ]);
    },
    async fetchSATTilsynLookups({ kundeId, virksomhedstype }: any) {
      await Promise.all([
        fetchLookup('dmalookup/tilsynsTyper', 'tilsynTyper'),
        fetchLookup(`sagsbehandler/list/${kundeId}?type=${virksomhedstype}`, 'sagsbehandler', true),
      ]);
    },
    async fetchGebyrLookups({ kundeId, virksomhedstype, date }: any) {
      await Promise.all([
        fetchLookup(
          `satgebyrtype/lookup/${kundeId}${date ? `?date=${date}` : ''}`,
          'gebyrTyper',
          true
        ),
        fetchLookup(`sagsbehandler/list/${kundeId}?type=${virksomhedstype}`, 'sagsbehandler', true),
        fetchLookup('tidsregistreringsKategori/listGebyr', 'tidsregistreringsKategorier', true),
      ]);
    },
  },
  getters: {
    virksomhederLookups(): Record<string, Lookup[]> {
      return {
        virksomhedsgrupper: this.virksomhedsgrupper,
      };
    },
    landbrugLookups(): Record<string, Lookup[]> {
      return {
        primaereDyreTyper: this.primaereDyreTyper,
        virksomhedsgrupper: this.virksomhedsgrupper,
      };
    },
    basisLookups(): Record<string, Lookup[]> {
      return {
        aktiviteter: this.aktiviteter,
        batKonklusioner: this.batKonklusioner,
        sagsbehandler: this.sagsbehandler,
        virksomhedsgrupper: this.virksomhedsgrupper,
        miljoemyndigheder: this.miljoemyndigheder,
        dmaStatus: this.dmaStatus,
        primaereDyreTyper: this.primaereDyreTyper,
      };
    },
    afgoerelserLookups(): Record<string, Lookup[]> {
      const sagsbehandlere = this.sagsbehandler ? this.sagsbehandler : [];
      return {
        afgoerelseVedrAnlaegsTyper: [new Lookup(), ...this.afgoerelseVedrAnlaegsTyper],
        afgoerelseStatusser: this.afgoerelseStatusser,
        afgoerelseBATAEL: this.afgoerelseBATAEL,
        afgoerelseResultater: this.afgoerelseResultater,
        dmaDokumenttype: [new Lookup(), ...this.dmaDokumenttype],
        dmaFortrolighedsNiveau: [new Lookup(), ...this.dmaFortrolighedsNiveau],
        sagsbehandler: [...sagsbehandlere],
      };
    },
    haandhaevelseLookups(): Record<string, Lookup[]> {
      const sagsbehandlere = this.sagsbehandler ? this.sagsbehandler : [];
      return {
        tilsynTyper: this.tilsynTyper,
        tilsynKategorier: this.tilsynKategorier,
        // 'afgoerelseVedrAnlaegsTyper': this.afgoerelseVedrAnlaegsTyper,
        haandhaevelseStatusser: this.haandhaevelseStatusser,
        haandhaevelseTyper: this.haandhaevelseTyper,
        haandhaevelseMiljoeForhold: this.haandhaevelseMiljoeForhold,
        haandhaevelseVedroererKrav: [new Lookup(), ...this.haandhaevelseVedroererKrav],
        haandhaevelseVedroererAnlaegstyper: [
          new Lookup(),
          ...this.haandhaevelseVedroererAnlaegstyper,
        ],
        sagsbehandler: [...sagsbehandlere],
      };
    },
    tilsynLookups(): Record<string, Lookup[]> {
      const sagsbehandlere = this.sagsbehandler ? this.sagsbehandler : [];
      return {
        tilsynTyper: this.tilsynTyper,
        tilsynKategorier: this.tilsynKategorier,
        tilsynBaggrunde: [new Lookup(), ...this.tilsynBaggrunde],
        tilsynVedroererAnlaegstyper: [new Lookup(), ...this.tilsynVedroererAnlaegstyper],
        tilsynVedroererKrav: [new Lookup(), ...this.tilsynVedroererKrav],
        haandhaevelseMiljoeForhold: [new Lookup(), ...this.haandhaevelseMiljoeForhold],
        dmaDokumenttype: [new Lookup(), ...this.dmaDokumenttype],
        dmaFortrolighedsNiveau: [new Lookup(), ...this.dmaFortrolighedsNiveau],
        miljoeRisikoVurderinger: [new Lookup(), ...this.miljoeRisikoVurderinger],
        sagsbehandler: [...sagsbehandlere],
      };
    },
    fyringsanlaegLookups(): Record<string, Lookup[]> {
      const sagsbehandlere = this.sagsbehandler ? this.sagsbehandler : [];
      return {
        fyringsanlaegsTyper: this.fyringsanlaegsTyper,
        fyringsanlaegVedroererNaceKoder: this.fyringsanlaegVedroererNaceKoder,
        fyringsanlaegNaceKoder: [new Lookup(), ...this.fyringsanlaegNaceKoder],
        fyringsanlaegNoedanlaegsTyper: [new Lookup(), ...this.fyringsanlaegNoedanlaegsTyper],
        fyringsanlaegAnlaegsTyper: this.fyringsanlaegAnlaegsTyper,
        fyringsanlaegBraendselsTyper: [new Lookup(), ...this.fyringsanlaegBraendselsTyper],
        fyringsanlaegBraendselsKategorier: [
          new Lookup(),
          ...this.fyringsanlaegBraendselsKategorier,
        ],
        sagsbehandler: [...sagsbehandlere],
        // fyringsanlaegVedroererNaceKoder: [new Lookup(), ...this.fyringsanlaegVedroererNaceKoder]
      };
    },
    pladserLookups(): Record<string, Lookup[]> {
      return {
        pladserPladsTyperLandbrug: this.pladserPladsTyperLandbrug,
        pladserPladsTyperVirksomheder: this.pladserPladsTyperVirksomheder,
        pladserArtifaktStatusser: this.pladserArtifaktStatusser,
        pladserSideafskaermninger: this.pladserSideafskaermninger,
      };
    },
    beholderLookups(): Record<string, Lookup[]> {
      return {
        beholdereBeholderTyperLandbrug: this.beholdereBeholderTyperLandbrug,
        beholdereBeholderTyperVirksomheder: this.beholdereBeholderTyperVirksomheder,
        beholdereArtifaktStatusser: this.beholdereArtifaktStatusser,
        beholdereOverdaekningsTyper: this.beholdereOverdaekningsTyper,
      };
    },
    dyreholdLookups(): Record<string, Lookup[]> {
      return {
        dyreholdStatusser: this.dyreholdStatusser,
        dyreholdOrdninger: this.dyreholdOrdninger,
        dyreholdArtifaktStatusser: this.dyreholdArtifaktStatusser,
        dyreholdStaldsystemer: this.dyreholdStaldsystemer,
        dyreholdStaldtyper: this.dyreholdStaldtyper,
        dyreholdDyreTyper: this.dyreholdDyreTyper,
        iOrden: [
          {
            id: null,
            beskrivelse: 'Ikke angivet',
            kode: '',
            navn: 'Ikke angivet',
          },
          {
            id: true,
            beskrivelse: 'Ja',
            kode: '',
            navn: 'Ja',
          },
          {
            id: false,
            beskrivelse: 'Nej',
            kode: '',
            navn: 'Nej',
          },
        ],
      };
    },
    risikovurderingLookups(): Record<string, Lookup[]> {
      return {
        risikoscoreTyper: this.risikoscoreTyper,
        virksomhedsgrupper: this.virksomhedsgrupper,
      };
    },

    kontaktpersonTypeLookups(): Record<string, Lookup[]> {
      return {
        kontaktpersonTyper: this.kontaktpersonTyper,
        kontaktpersonBetalerTyper: this.kontaktpersonBetalerTyper,
      };
    },

    tidsregistreringLookups(): Record<string, Lookup[]> {
      const sagsbehandlere = this.sagsbehandler ? this.sagsbehandler : [];
      return {
        brugerbetalingTidsregistreringsKategorier: [
          new Lookup(),
          ...this.brugerbetalingTidsregistreringsKategorier,
        ],
        sagsbehandler: [...sagsbehandlere],
      };
    },

    gebyrLookups(): Record<string, Lookup[]> {
      const sagsbehandlere = this.sagsbehandler ? this.sagsbehandler : [];
      return {
        gebyrTyper: [...this.gebyrTyper],
        sagsbehandler: [...sagsbehandlere],
        tidsregistreringsKategorier: [new Lookup(), ...this.tidsregistreringsKategorier],
      };
    },

    fremmedtjenesteydelseLookups(): Record<string, Lookup[]> {
      const sagsbehandlere = this.sagsbehandler ? this.sagsbehandler : [];
      return {
        brugerbetalingFremmedTjenesteydelseKategorier: [
          new Lookup(),
          ...this.brugerbetalingFremmedTjenesteydelseKategorier,
        ],
        sagsbehandler: [...sagsbehandlere],
      };
    },

    aarsplanlaegningLookups(): Record<string, Lookup[]> {
      return {
        tilsynKategorier: this.tilsynKategorier,
      };
    },

    tilsynsskabelonerLookups(): Record<string, Lookup[]> {
      return {
        templateEmner: this.templateEmner,
        booleanLookups: [
          {
            id: true,
            beskrivelse: 'Ja',
            kode: '',
            navn: 'Ja',
          },
          {
            id: false,
            beskrivelse: 'Nej',
            kode: '',
            navn: 'Nej',
          },
        ],
      };
    },

    kontrolpunktsskabelonerLookups(): Record<string, Lookup[]> {
      return {
        templateEmner: this.templateEmner,
        booleanLookups: [
          {
            id: true,
            beskrivelse: 'Ja',
            kode: '',
            navn: 'Ja',
          },
          {
            id: false,
            beskrivelse: 'Nej',
            kode: '',
            navn: 'Nej',
          },
        ],
      };
    },

    fakturaforslagLookups(): Record<string, Lookup[]> {
      return {
        sagsbehandler: this.sagsbehandler,
        fordringstyper: this.fordringstyper,
      };
    },

    satTilsynsperiodeLookups(): Record<string, Lookup[]> {
      return {
        satTilsynsperioder: this.satTilsynsperioder,
      };
    },

    satResultatKategoriLookups(): Record<string, Lookup[]> {
      return {
        satResultatKategorier: [
          {
            id: SATResultatKategoriEnum.UdtrukketAfSAT,
            beskrivelse: SATResultatKategoriEnum.UdtrukketAfSAT,
            kode: '',
            navn: 'Udtrukket til tilsyn af Miljøstyrelsen',
          },
          {
            id: SATResultatKategoriEnum.DelAfMiljoeTilsyn,
            beskrivelse: SATResultatKategoriEnum.DelAfMiljoeTilsyn,
            kode: '',
            navn: 'Er en del af miljøtilsyn',
          },
          {
            id: SATResultatKategoriEnum.Paragraf8,
            beskrivelse: SATResultatKategoriEnum.Paragraf8,
            kode: '',
            navn: 'Affaldstilsyn paragraf 8',
          },
          {
            id: SATResultatKategoriEnum.BygOgKonstruktion,
            beskrivelse: SATResultatKategoriEnum.BygOgKonstruktion,
            kode: '',
            navn: 'Tilsyn ifm. bygning/konstruktion',
          },
          {
            id: SATResultatKategoriEnum.AndreTemaer,
            beskrivelse: SATResultatKategoriEnum.AndreTemaer,
            kode: '',
            navn: 'Andre tilsyn (eks. pulje)',
          },
        ],
      };
    },

    satResultatTypeLookups(): Record<string, Lookup[]> {
      return {
        satResultatTyper: this.satResultatTyper,
      };
    },
    satTilsynLookups(): Record<string, Lookup[]> {
      const sagsbehandlere = this.sagsbehandler ? this.sagsbehandler : [];
      return {
        tilsynTyper: this.tilsynTyper,
        sagsbehandler: [...sagsbehandlere],
      };
    },
  },
});

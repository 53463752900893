import { HttpResponse } from "@/utils/networking/interfaces";
import { defineStore } from "pinia";

import { fetchDelete, fetchGet, fetchPost, fetchPut } from "@/utils/networking/httpclient";

import { SATHaandhaevelse } from "@/models/SAT/SATHaandhaevelse";
import { SATResultat } from "@/models/SAT/SATResultat";
import SATTilsynModel from "@/models/SAT/SATTilsynModel";
import { SATTilsynsperiodeModel } from "@/models/SAT/SATTilsynsperiodeModel";
import SATVirksomhed from "@/models/SAT/SATVirksomhed";
import { SATVirksomhedOpretModel } from "@/models/SAT/SATVirksomhedOpretModel";
import { SATVirksomhederOpretFraCVRModel } from "@/models/SAT/SATVirksomhederOpretFraCVRModel";
import { Gebyr } from "@/models/virksomhed/gebyr";
import { IMwTableFetchItemsParams } from "@/utils/interfaces";
import { createMwTableFetchItemsUrl } from "@/utils/utils";
import { SATVirksomhedState } from "./statetypes";

interface CompanyParams {
  id?: string,
  type?: string
}

export const useSATStore = defineStore("sat", {
  state: (): SATVirksomhedState => {
    return {
      currentSATVirksomhed: new SATVirksomhed()
    };
  },
  actions: {
    async fetchSATTilsynsperioder(kundeId: string): Promise<SATTilsynsperiodeModel[]> {
      const response: HttpResponse = await fetchGet("SATTilsynsperiode/tilsynsperioder/" + kundeId);
      return response.data as SATTilsynsperiodeModel[];
    },
    //#region SATVIRKSOMHED
    async createSATVirksomhedManuel(createSATVirksomhed: SATVirksomhedOpretModel): Promise<HttpResponse> {
      const response: HttpResponse = await fetchPost(
        "SATVirksomhed/OpretSATVirksomhedManuelt?KundeId=" +
          createSATVirksomhed.kundeId +
          "&satTilsynsperiodeId=" +
          createSATVirksomhed.satTilsynsperiodeId,
        createSATVirksomhed
      );
      return response;
    },

    async validateOpretSATVirksomhed(createSATVirksomhed: SATVirksomhedOpretModel): Promise<HttpResponse> {
      const response: HttpResponse = await fetchPost(
        "SATVirksomhed/ValidateOpretSATVirksomhed?KundeId=" +
          createSATVirksomhed.kundeId +
          "&satTilsynsperiodeId=" +
          createSATVirksomhed.satTilsynsperiodeId,
        createSATVirksomhed
      );
      return response;
    },

    async createSATVirksomhederFraCVR(satVirksomhederOpretFraCVR: SATVirksomhederOpretFraCVRModel): Promise<HttpResponse> {
      const response: HttpResponse = await fetchPost(
        "SATVirksomhed/OpretSATVirksomhederFraCVR?KundeId=" +
          satVirksomhederOpretFraCVR.kundeId +
          "&satTilsynsperiodeId=" +
          satVirksomhederOpretFraCVR.satTilsynsperiodeId,
        satVirksomhederOpretFraCVR
      );
      return response;
    },

    async fetchSatVirksomhed(satvirksomhedid: string): Promise<HttpResponse> {
      const response = await fetchGet("satvirksomhed/" + satvirksomhedid);
      const satvirksomhed: SATVirksomhed = Object.assign(new SATVirksomhed(), response.data);
      this.currentSATVirksomhed = satvirksomhed;
      return response;
    },

    async updateSatVirksomhed(satvirksomhed: SATVirksomhed): Promise<HttpResponse> {
      const response = await fetchPut(`satvirksomhed/${satvirksomhed.id}`, satvirksomhed);
      this.currentSATVirksomhed = Object.assign(new SATVirksomhed(), response.data);
      return response;
    },
    
    //#endregion

    //#region SATRESULTAT
    async createSatResultatForSatVirksomhed(satResultat: SATResultat): Promise<HttpResponse> {
      const response = await fetchPost(`satresultat/satvirksomhed/${this.currentSATVirksomhed.id}`, satResultat);
      this.currentSATVirksomhed.satResultat = Object.assign(new SATResultat(), response.data);
      return response;
    },

    async createSatResultatForVirksomhed(satResultat: SATResultat, virksomhedId: string): Promise<HttpResponse> {
      const response = await fetchPost(`satresultat/virksomhed/${virksomhedId}`, satResultat);
      return response;
    },

    async updateSatResultat(satResultat: SATResultat): Promise<HttpResponse> {
      const response = await fetchPut(`satresultat/${satResultat.id}`, satResultat);
      this.currentSATVirksomhed.satResultat = Object.assign(new SATResultat(), response.data);
      return response;
    },

    async deleteSatResultat(satResultatId: string): Promise<HttpResponse> {
      const response = await fetchDelete(`satresultat/${satResultatId}`);
      this.currentSATVirksomhed.satResultat = Object.assign(new SATResultat(), response.data);
      return response;
    },

    async getSATResultat(satResultatId: string): Promise<HttpResponse> {
      const response: HttpResponse = await fetchGet("satresultat/" + satResultatId);
      this.currentSATVirksomhed.satResultat = Object.assign(new SATResultat(), response.data);      
      return response;
    },

    async transferSATResultToSAT(satResultatId: string): Promise<HttpResponse> {
      const response: HttpResponse = await fetchPost("satresultat/OpretOpdaterSATResultatISAT/" + satResultatId, null);
      return response;
    },

    async deleteSATResultInSAT(satResultatId: string): Promise<HttpResponse> {
      const response: HttpResponse = await fetchPost("satresultat/SletSATResultatISAT/" + satResultatId, null);
      return response;
    },

    //#endregion

    //#region SATHÅNDHÆVELSE
    async fetchSATHaandhaevelser() {
      return await fetchGet(`sathaandhaevelse/list/${this.currentSATVirksomhed.id}`);
    },

    async createSATHaandhaevelse(satHaandhaevelse: SATHaandhaevelse): Promise<HttpResponse> {
      return await fetchPost(`sathaandhaevelse/${this.currentSATVirksomhed.id}`, satHaandhaevelse);
    },

    async updateSATHaandhaevelse(satHaandhaevelse: SATHaandhaevelse): Promise<HttpResponse> {
      return await fetchPut(`sathaandhaevelse/${satHaandhaevelse.id}`, satHaandhaevelse);
    },

    async deleteSATHaandhaevelse(satHaandhaevelseId: string): Promise<HttpResponse> {
      return await fetchDelete(`sathaandhaevelse/${satHaandhaevelseId}`);
    },
    //#endregion

    //#region SATTILSYN
    async fetchSatTilsynList(): Promise<HttpResponse> {
      return await fetchGet("sattilsyn/list/" + this.currentSATVirksomhed.id);
    },

    async createSATTilsyn(satTilsyn: SATTilsynModel) {
      return await fetchPost(`sattilsyn/${this.currentSATVirksomhed.id}`, satTilsyn);
    },
    async updateSATTilsyn(sattilsyn: SATTilsynModel): Promise<HttpResponse> {
      return await fetchPut(`sattilsyn/${sattilsyn.id}`, sattilsyn);
    },
    async deleteSATTilsyn(satTilsynId: string): Promise<HttpResponse> {
      return await fetchDelete(`sattilsyn/${satTilsynId}`);
    },
    async fetchSatTilsyn(satTilsynId: string): Promise<HttpResponse> {
      const { status: status, data } = await fetchGet("sattilsyn/" + satTilsynId);
      return data;
    },
    //#endregion

    //#region SATGEBYR
    

    async fetchSATGebyrList(params: IMwTableFetchItemsParams, companyParams: CompanyParams ): Promise<HttpResponse> {
      const newURL = createMwTableFetchItemsUrl(`satgebyr/${companyParams.type}/list/${companyParams.id}`, params)
      return await fetchGet(newURL);
    },
    async createSATGebyr(satGebyr: Gebyr,  companyParams: CompanyParams) {
      return await fetchPost(`satgebyr/${companyParams.type}/${companyParams.id}`, satGebyr);
    },
    async updateSATGebyr(satGebyr: Gebyr): Promise<HttpResponse> {
      return await fetchPut(`satgebyr/${satGebyr.id}`, satGebyr);
    },
    async deleteSATGebyr(satGebyrId: string): Promise<HttpResponse> {
      return await fetchDelete(`satgebyr/${satGebyrId}`);
    },
    //#endregion
  },
  getters: {}
});

import { OpfoelgningOekonomi } from "@/models/planlaegning";
import { PlanlaegningState } from "@/stores/statetypes";
import { fetchDelete, fetchGet, fetchPost, fetchPut } from "@/utils/networking/httpclient";
import { HttpResponse } from "@/utils/networking/interfaces";
import { defineStore } from "pinia";

export const usePlanlaegningStore = defineStore("planlaegning", {
  state: (): PlanlaegningState => {
    return {
      opfoelgningAfOekonomiModel: null,
      currentPlanlaegningsPeriodeId: "",
      isUserInfoButtonVisible: true,
      isGraphButtonVisible: true
    };
  },
  actions: {
    async fetchOpfoelgningOekonomiList(planlaegningsPeriodeId: any): Promise<HttpResponse> {
      const response = await fetchGet("aarsplanlaegning/opfoelgningOekonomiData/list/" + planlaegningsPeriodeId);

      this.opfoelgningAfOekonomiModel = data;
      return { status, data };
    },
    async updateOpfoelgningOekonomi(OpfoelgningOekonomi: OpfoelgningAfOekonomiModel): Promise<HttpResponse> {
      return await fetchPut("aarsplanlaegning/opfoelgningOekonomiData/" + OpfoelgningOekonomi.id, OpfoelgningOekonomi);
    },
    async createOpfoelgningOekonomi(OpfoelgningOekonomi: OpfoelgningOekonomi): Promise<HttpResponse> {
      return await fetchPost("aarsplanlaegning/opfoelgningOekonomiData", OpfoelgningOekonomi);
    },
    async deleteOpfoelgningOekonomi(budgetId: string): Promise<HttpResponse> {
      return await fetchDelete("aarsplanlaegning/opfoelgningOekonomiData/" + budgetId);
    },
    clearState(): void {
      this.opfoelgningAfOekonomiModel = null;
    },
    toggleUserInfoButton(value: boolean) {
      if (value === undefined) {
        this.isUserInfoButtonVisible = !this.isUserInfoButtonVisible;
      } else {
        this.isUserInfoButtonVisible = value;
      }
    },

    toggleGraphButton(value: boolean) {
      if (value === undefined) {
        this.isGraphButtonVisible = !this.isGraphButtonVisible;
        this.isGraphButtonVisible = value;
      } else {
      }
    }
  },
  getters: {}
});

<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <label v-if="label" :class="{ disabledMultiselectLabel: labelDisabled }"
        >{{ label + (isRequired ? " *" : "") }}<span v-if="dmaValidation" class="externallyRequired pink-dark-color">*<sup>DMA</sup></span>
        <span v-else-if="jupiterValidation" class="externallyRequired pink-dark-color">*<sup>Jupiter</sup></span>
        <span v-else-if="satValidation" class="externallyRequired pink-dark-color">*<sup>SAT</sup></span>
        </label
      >

      <slot name="label-extra"></slot>
    </div>

    <div class="form-group" :class="{ 'has-danger': validations?.$invalid }" :title="computedTitle">
      <div class="inputField" :class="{ externalField: isExternalField, open: open, infoButtonActive: infoButtonOptions }">
        <p v-if="dmaField || dmaValidation" title="Indberettes til DMA">D</p>
        <p v-if="jupiterField || jupiterValidation" :title="jupiterShowOnly ? 'Hentes fra Jupiter' : 'Indberettes til Jupiter'">J</p>
        <p v-if="satField || satValidation" title="Indberettes til SAT">S</p>
        <VueMultiselect
          :modelValue="vueMultiselectModelValue"
          @update:modelValue="onModelValueUpdated"
          :options="getOptions"
          :close-on-select="!multiple"
          :multiple="multiple"
          :placeholder="disabled ? '' : placeholder"
          selectedLabel
          selectLabel
          deselectLabel
          :resetAfter="resetAfterSelection"
          :trackBy="trackBy"
          @open="open = true"
          @close="validateInput"
          @blur="validateInput"
          :label="labelAttribute"
          :disabled="disabled"
          :allow-empty="multiple ? true : false"
          noOptions="Listen er tom"
          ref="multiselectField"
          tagPlaceholder="Vælg for at oprette som ny"
          tagPosition="bottom"
        >
          <template v-slot:singleLabel="{ option }">
            <div :title="option[labelAttribute]">
              {{ option[labelAttribute] }}
              {{ option.dmaKode === false && !props.ignoreIkkeDmaText ? "(Ikke DMA)" : "" }}
            </div>
          </template>
          <template v-slot:option="slotProps">
            <div :title="tooltipProperty ? slotProps.option[tooltipProperty] : slotProps.option[labelAttribute]" disabled="true">
              {{ slotProps.option[labelAttribute] }}
              {{ slotProps.option.dmaKode === false && !props.ignoreIkkeDmaText ? "(Ikke DMA)" : "" }}
              <span v-if="optionLink" class="linkIcon">
                <a @click="redirect(slotProps.option, $event)"><i class="fa fa-share"></i></a>
              </span>
            </div>
          </template>
          <template v-if="multiple && priority" v-slot:tag="slotProps">
            <span class="multiselect__tag"
              ><span>({{ slotProps.option[priorityLabel] }}) {{ slotProps.option[labelAttribute] }}</span>
              <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="slotProps.remove(slotProps.option)"></i
            ></span>
          </template>
          <template v-slot:noOptions>
            <span>{{ noOptionsText }}</span>
          </template>
          <template v-slot:noResult>
            <span>Ingen resultater</span>
          </template>
        </VueMultiselect>
      </div>
      <div v-if="infoButtonOptions && !multiple" class="infoButtonOptionsContainer">
        <MwInfoIcon
          v-if="infoButtonOptions"
          :header="infoButtonOptions.header"
          :content="infoButtonContent"
          :icon="infoButtonOptions.icon"
          class="ml-2 infoIconButton"
        ></MwInfoIcon>
      </div>
      <div class="form-control-feedback" v-if="hasErrors">{{ lastError }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import MwInfoIcon from "@/components/mwUtils/MwInfoIcon.vue";
import { computed, inject, ref } from "vue";
import { useValidations } from "@/composables/useValidations";
import VueMultiselect from "vue-multiselect";
import { ChangeTrackerSetChangedFunction } from "@/components/mwUtils/changeTracker/ChangeTracker";

interface ModelValueOption {
  [key: string | number]: any;
  aktiv?: boolean;
}

interface IInfoButtonOptions {
  icon?: string;
  header?: string;
  content: string;
  disabled?: boolean;
}

interface Props {
  /**
   * @description Bruges når props.modelValue er et objekt, og props.options er et array af objekter
   */
  trackBy?: string;
  label?: string;
  labelAttribute?: string;
  tooltipProperty?: string;
  overridingTooltip?: string;
  placeholder?: string;
  disabled?: boolean;
  labelDisabled?: boolean;
  multiple?: boolean;
  options?: SelectOptions;
  modelValue?: string | string[] | ModelValueOption | ModelValueOption[] | boolean | boolean[] | null;
  /**
   * @description Bruges hvis modelValue er en string og props.options er et array af objekter.
   * Hvis modelAttribute er sat, så vil kompontenten emitte modelValue objektet værdi
   * Hvis modelAttribute er sat til "null" så vil komponenten emitte modelValue objektet
   */
  modelAttribute?: string | null;
  validations?: any;
  autoSort?: boolean;
  optionLink?: boolean;
  ignoreChangeTracking?: boolean;
  ignoreOldValueRecreation?: boolean;
  noOptionsText?: string;
  resetAfterSelection?: boolean;
  bypassDmaValidation?: boolean;
  dmaValidation?: boolean;
  dmaField?: boolean;
  jupiterValidation?: boolean;
  jupiterField?: boolean;
  jupiterShowOnly?: boolean;
  satValidation?: boolean;
  satField?: boolean;
  /**
   * @description Understøtter ikke multiple=true
   */
  infoButtonOptions?: IInfoButtonOptions;
  priority?: boolean;
  priorityStartIndex?: number;
  priorityLabel?: string;
  /**
   * @description Bruges til at fjerne '(Ikke DMA)' teksten
   */
  ignoreIkkeDmaText?: boolean;
}

//TODO VueMultiSelect understøtter string[] og det gør SdpSelectAdv også. Når vi skifter til SdpSelectAdv skal vi overveje om MwSelect også skal.
const props = withDefaults(defineProps<Props>(), {
  labelAttribute: "navn",
  modelAttribute: "id", // Som default forventer vi props.options er Lookup[], så derfor sætter vi modelAttribute til "id"
  trackBy: "id",
  tooltipProperty: "",
  overridingTooltip: "",
  placeholder: "Skriv for at søge",
  disabled: false,
  labelDisabled: false,
  multiple: false,
  options: () => [],
  modelValue: () => [] || "",
  validations: null,
  autoSort: true,
  optionLink: false,
  ignoreChangeTracking: false,
  ignoreOldValueRecreation: false,
  noOptionsText: "Listen er tom",
  resetAfterSelection: false,
  bypassDmaValidation: false,
  dmaValidation: false,
  dmaField: false,
  jupiterValidation: false,
  jupiterField: false,
  jupiterShowOnly: false,
  satValidation: false,
  priority: false,
  priorityStartIndex: 1,
  priorityLabel: "prioritet",
  ignoreIkkeDmaText: false
});

type SelectOptions = ModelValueOption[]; // || string[]; // || Lookup[]

const emit = defineEmits<{
  (eventName: "update:options", options: SelectOptions): void;
  (eventName: "update:modelValue", value: any, previousValue?: any): void;
  (eventName: "optionLinkClicked", options: SelectOptions, event: Event): void;
  (eventName: "select"): void;
}>();

const localSelectedOptions = computed((): SelectOptions => {
  const values = [];

  if (!props.modelValue || props.options.length === 0) {
    return [];
  }

  if (props.multiple && Array.isArray(props.modelValue)) {
    const ids = props.modelValue.map((value: any) => {
      if (isModelStringBased()) {
        return value;
      } else {
        return value[props.modelAttribute];
      }
    });

    ids.forEach((id: string) => {
      const foundValue = props.options.find(option => {
        return option[props.modelAttribute] === id;
      });
      if (foundValue) {
        values.push(foundValue);
      }
    });
  } else {
    const foundValue = props.options.find(option => {
      return option[props.modelAttribute] === props.modelValue;
    });
    if (foundValue) {
      values.push(foundValue);
    }
  }

  return values;
});

let originalValue: any | null = null;
const open = ref(false);

const setTrackerChanged = inject<ChangeTrackerSetChangedFunction>("setChanged", () => null);

function onModelValueUpdated(newValue: any): void {
  const previousValue: any = props.modelValue;

  if (props.priority) {
    newValue = setPriority(newValue);
  }

  let value = null;

  const hasValue = newValue !== null && newValue !== undefined;

  if (!hasValue) {
    value = newValue;
  }

  if (previousValue === null || previousValue.value === null) {
    if (isModelValueObjectBased()) {
      value = newValue;
    } else if (props.options && props.options.length > 0) {
      const type = getTypeBasedOnOptions();

      if (type === "string" || type === "boolean") {
        if (props.multiple) {
          value = newValue.map((v: any) => v[props.modelAttribute]);
        } else {
          value = newValue[props.modelAttribute];
        }
      }
    }
  } else if (hasValue && (isModelStringBased() || isModelBooleanBased())) {
    if (props.multiple) {
      value = newValue.map((v: any) => v[props.modelAttribute]);
    } else {
      value = newValue[props.modelAttribute];
    }
  } else if (isModelValueObjectBased()) {
    value = newValue;
  }

  emit("update:modelValue", value, previousValue);

  emit("select");

  if (setTrackerChanged) {
    setTrackerChanged();
  }
}

function getTypeBasedOnOptions() {
  let type = null;

  if (!props.modelAttribute) {
    return type;
  }

  const firstOption = props.options[0] ? props.options[0][props.modelAttribute] : null;
  const secondOption = props.options[1] ? props.options[1][props.modelAttribute] : null;

  if (firstOption !== null) {
    type = typeof firstOption;
  } else if (secondOption !== null) {
    type = typeof secondOption;
  }

  return type;
}

function typeofModelValue() {
  if (isModelValueArray()) {
    return typeof props.modelValue[0];
  }

  return typeof props.modelValue;
}

// Funktionen er ikke længere en computed værdi for at gøre det mere overskuelig at debugge koden og for at undgå reactivity hell
function isModelValueArray() {
  return Array.isArray(props.modelValue);
}

// Funktionen er ikke længere en computed værdi for at gøre det mere overskuelig at debugge koden og for at undgå reactivity hell
/**
 * @description
 * Hvis props.modelAttribute er sat, så vil kompontenten emitte modelValue objektet værdi
 * Hvis props.modelAttribute er sat til "null" så vil komponenten emitte modelValue objektet
 */
function isModelValueObjectBased() {
  return props.modelAttribute === null || props.modelAttribute === undefined || props.modelAttribute === "";
}

// Funktionen er ikke længere en computed værdi for at gøre det mere overskuelig at debugge koden og for at undgå reactivity hell
function isModelStringBased() {
  if (isModelValueObjectBased()) {
    return false;
  }

  if (typeofModelValue() === "string") {
    return true;
  }

  if (props.options.length > 0 && !isOptionsStringArray()) {
    if (getTypeBasedOnOptions() === "string") {
      return true;
    }
  }

  return false;
}

// Funktionen er ikke længere en computed værdi for at gøre det mere overskuelig at debugge koden og for at undgå reactivity hell
function isModelBooleanBased() {
  if (isModelValueObjectBased()) {
    return false;
  }

  if (typeofModelValue() === "boolean") {
    return true;
  }

  if (props.options.length > 0) {
    if (getTypeBasedOnOptions() === "boolean") {
      return true;
    }
  }

  return false;
}

// Funktionen er ikke længere en computed værdi for at gøre det mere overskuelig at debugge koden og for at undgå reactivity hell
function isOptionsStringArray() {
  return props.options && typeof props.options[0] === "string";
}

function hasValidProps() {
  //modelValue=string && :options = object[] - use modelAttribute
  //modelValue=obj - options = object[] - use trackBy

  if (!props.modelValue) {
    //Skip props validation;
    return true;
  }

  let isArray = false;

  if (props.multiple) {
    isArray = Array.isArray(props.modelValue);

    if (!isArray) {
      console.error("MwSelect %o blev sat til multiple=true, men modelValue er ikke et array: %o", props.label, props.modelValue);
      return false;
    }
  }

  if (isModelStringBased() && !isOptionsStringArray()) {
    if (!props.modelAttribute) {
      console.error("MwSelect %o mangler en modelAttribute prop", props.label);
      return false;
    }
  }

  if (!isModelStringBased() && !isOptionsStringArray()) {
    if (!props.trackBy) {
      console.error("MwSelect %o mangler en trackBy prop", props.label);
      return false;
    }
  }

  return true;
}

const vueMultiselectModelValue = computed(() => {
  hasValidProps();

  if (props.modelValue === "") {
    return "";
  }

  if (props.modelValue === null || props.modelValue === undefined) {
    return props.options.find(option => option[props.modelAttribute] === props.modelValue);
  }

  if (Array.isArray(props.modelValue) && props.modelValue.length === 0) {
    return [];
  }

  if (isModelStringBased()) {
    if (props.multiple && Array.isArray(props.modelValue)) {
      return props.modelValue.map(value => {
        return props.options.find(option => option[props.modelAttribute] === value);
      });
    }

    return props.options.find(option => option[props.modelAttribute] === props.modelValue);
  } else if (isModelBooleanBased()) {
    if (props.multiple && Array.isArray(props.modelValue)) {
      //Vi underrstøtter ikke boolean array, da det ikke giver mening
    }

    return props.options.find(option => option[props.modelAttribute] === props.modelValue);
  } else {
    if (props.multiple && Array.isArray(props.modelValue)) {
      return props.modelValue.map(value => {
        return props.options.find(option => option[props.trackBy] === value[props.trackBy]);
      });
    }

    return props.options.find(option => option[props.trackBy] === props.modelValue[props.trackBy]);
  }
});

const infoButtonContent = computed(() => {
  if (props.infoButtonOptions) {
    const content = props.infoButtonOptions.content;
    if (content.startsWith("#")) {
      if (props.multiple) {
        //props.multiple understøtter ikke # funktionalitet
        return "";
      }

      const field = content.substring(1);

      const selectedOption = localSelectedOptions.value[0];
      if (!selectedOption) {
        return "";
      }

      let value;
      if (selectedOption) {
        if (typeof selectedOption === "string") {
          value = selectedOption;
        } else {
          value = selectedOption[field];
        }
      }

      if (value) {
        return value;
      } else {
        console.error("Feltet %o findes ikke på objektet %o", field, selectedOption);
        return "";
      }
    }

    return props.infoButtonOptions.content;
  }

  return "";
});

function filterInactiveOptions() {
  if (isOptionsStringArray()) {
    return [...props.options];
  }

  const filteredList = [...props.options].filter(x => {
    if (typeof x === "string") {
      return;
    } else {
      return (
        x.aktiv === undefined ||
        x.aktiv === true ||
        //@ts-ignore
        (localSelectedOptions.value ? localSelectedOptions.value[props.trackBy] == x[props.trackBy] : false)
      );
    }
  });

  if (originalValue && !props.ignoreOldValueRecreation) {
    //Hvis den oprindelige inaktive værdi er filtreret fra i listen (da den er inaktiv), tilføjer vi den igen så brugeren har mulighed for at vælge den igen
    if (props.multiple && props.trackBy !== undefined) {
      originalValue.forEach((orgValue: any) => {
        const orignalValueAlreadyExists = filteredList.find(x => x[props.trackBy] === orgValue[props.trackBy]);
        if (!orignalValueAlreadyExists) {
          filteredList.push(orgValue);
        }
      });
    } else {
      const orignalValueAlreadyExists = filteredList.find(x => x[props.trackBy] === originalValue[props.trackBy]);
      if (!orignalValueAlreadyExists) {
        filteredList.push(originalValue);
      }
    }
  }
  return filteredList;
}

function redirect(option: SelectOptions, event: Event) {
  emit("optionLinkClicked", option, event);
}

const getOptions = computed(() => {
  const localOptions = filterInactiveOptions();

  if (props.autoSort && localOptions) {
    localOptions.sort((a: any, b: any) => {
      if (!a[props.labelAttribute]) {
        return 0;
      }
      return a[props.labelAttribute].toLowerCase() < b[props.labelAttribute].toLowerCase()
        ? -1
        : a[props.labelAttribute].toLowerCase() > b[props.labelAttribute].toLowerCase()
        ? 1
        : 0;
    });
  }
  return localOptions;
});

function setPriority(newValue: any[]) {
  return newValue
    .sort((a: any, b: any) => a[props.priorityLabel] - b[props.priorityLabel])
    .map((item: any, index: any) => {
      item[props.priorityLabel] = props.priorityStartIndex + index;
      return item;
    });
}

function validateInput() {
  open.value = false;
  // validationError = "";
  // if (props.validations) {
  //   if (!validationError && validations.required === false) {
  //     validationError = "Feltet er påkrævet";
  //   }
  //   if (!validationError && validations.minLength === false) {
  //     validationError = $t.errorMinLength(validations.$params.minLength.min);
  //   }
  // }
  // //Vi er nødt til at bruge bypassDmaValidation da nogle af de lookups vi angiver i frontend har ids som true/false/null. Det er noget DMA kræver desværre.
  // if (!validationError && dmaValidation && !bypassDmaValidation) {
  //   if (!localValue || localValue.id === null || (localValue.id === "" && !localValue.dmaKode)) {
  //     validationError = "Feltet er påkrævet af DMA";
  //   }
  // }
}

const isExternalField = computed(() => {
  return props.dmaValidation || props.dmaField || props.jupiterValidation || props.jupiterField || props.satField || props.satValidation;
});

const { hasErrors, lastError, isRequired } = useValidations(props.validations);

const computedTitle = computed(() => {
  if (props.overridingTooltip) {
    return props.overridingTooltip;
  } else {
    let title = "";

    if (localSelectedOptions.value && props.disabled) {
      title = localSelectedOptions.value
        .map(option => {
          return option[props.modelAttribute];
        })
        .join(", ");
    }

    return title;
  }
});
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.selectedStyle {
  background: #b586a4;
}

.inputField.externalField {
  position: relative;
}

.inputField.externalField:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  display: block;
  border-left: 27px solid transparent;
  border-bottom: 27px solid transparent;
  border-top: 27px solid #8cb58a;
  z-index: 47;
  opacity: 0.7;
  pointer-events: none;
}

.inputField.externalField.open {
  z-index: 49;
}

.inputField.externalField p {
  position: absolute;
  font-size: 0.73rem;
  right: 0;
  color: #f3f3f3;
  z-index: 48;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  margin-bottom: 0;
  cursor: default;
}
</style>

<style>
.multiselect {
  cursor: pointer;
}

.multiselect__tags {
  border: 1px solid #a5a5a8;
  background-clip: padding-box;
  border-radius: 0;
}

.form-group.has-danger .multiselect__tags {
  border-color: #821307;
}

.multiselect__tag {
  background: #8aa3b9;
}

.multiselect__tag-icon:hover {
  background: #7e8dab;
}

.multiselect__option--highlight {
  background: #8aa3b9;
}

.multiselect__tag-icon:after {
  color: #ffffff;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #b586a4;
}

.multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.multiselect--above .multiselect__content-wrapper,
.multiselect__content-wrapper {
  border-radius: 0;
  border: 1px solid #a5a5a8;
  border-top: none;
}

.disabledMultiselectLabel {
  opacity: 0.4;
}

.multiselect--disabled .multiselect__tags,
.multiselect--disabled .multiselect__tags .multiselect__single {
  background: #eee;
}

.multiselect--disabled {
  opacity: 1;
}

.multiselect__element .linkIcon {
  float: right;
  color: #cccccc;
  font-size: 0.9em;
}

.multiselect__element .linkIcon:hover {
  color: #fff;
}

.template-chooser .multiselect__tags {
  border-top: dashed 1px #ccccce;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.template-chooser.focus .multiselect__tags {
  border-bottom-color: #727275;
  border-left-color: #727275;
  border-right-color: #727275;
}

.multiselect--disabled .multiselect__tag {
  padding-right: 10px;
}

.multiselect--disabled .multiselect__tag-icon {
  display: none;
}

.infoButtonActive {
  float: left;
  width: calc(100% - 40px);
  margin-bottom: 0.95rem;
}

.infoButtonOptionsContainer {
  width: 40px;
  height: 40px;
  float: left;
}

.infoButtonOptionsContainer > button {
  height: 100%;
}
</style>
<style scoped>
.infoIconButton :deep(button) {
  height: 40px;
  position: absolute;
}
</style>
